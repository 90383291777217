import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontMerriweather } from "@product/scmp-sdk";

import { Figure } from "scmp-app/components/content/content-cover-image/styles";
import { ContentItemHomeSecondary } from "scmp-app/components/content/content-item-render/variants/home-secondary";
import {
  CoverImage,
  Headline,
  StyledCoverEntityLink,
  StyledHeadlineEntityLink,
} from "scmp-app/components/content/content-item-render/variants/home-secondary/styles";
import { ContentItemSectionTopLeadPrimary } from "scmp-app/components/content/content-item-render/variants/section-top-lead-primary";
import {
  Headline as LeadHeadline,
  LeadingImage,
  SubHeadline as LeadSubHeadline,
  Summary as LeadSummary,
} from "scmp-app/components/content/content-item-render/variants/section-top-lead-primary/styles";
import { SectionLink } from "scmp-app/components/content/content-sections/styles";
import { EntityLink } from "scmp-app/components/entity-link";

const itemBorderStyle = css`
  margin-block-end: 20px;
  padding-block-end: 20px;
  border-block-end: 1px solid rgba(0, 0, 0, 0.1);
`;

export const StyledContentItemHomeSecondary = styled(ContentItemHomeSecondary)``;
export const StyledContentItemSectionTopLeadPrimary = styled(ContentItemSectionTopLeadPrimary)``;
export const StyledEntityLink = styled(EntityLink)`
  display: block;
`;

export const Container = styled.div``;

export const PrimaryRest = styled.div`
  ${StyledContentItemHomeSecondary} {
    &:not(:last-child) {
      ${itemBorderStyle};
    }

    ${Headline} {
      font-size: 15px;
      line-height: 140%;
    }
  }
`;

export const Primary = styled.div`
  ${itemBorderStyle}

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 0;
    padding-block-end: 0;
    border-block-end: none;
  }

  ${LeadingImage} {
    position: relative;
    ${Figure} {
      aspect-ratio: 3/2;

      ${props => props.theme.breakpoints.up("tablet")} {
        aspect-ratio: 250/99;
      }
    }
  }

  ${LeadHeadline} {
    font-size: 18px;
    line-height: 140%;

    ${props => props.theme.breakpoints.up("tablet")} {
      font-size: 22px;
    }
  }

  ${LeadSubHeadline} {
    display: none;
    ${props => props.theme.breakpoints.up("tablet")} {
      display: block;

      margin-block-end: 12px;
    }
  }

  ${LeadSummary} {
    ${props => props.theme.breakpoints.up("tablet")} {
      display: none;
    }
  }

  ${SectionLink} {
    color: #001246;
    font-size: 15px;
    line-height: 18px;
  }
`;

export const Secondary = styled.div`
  display: flex;
  flex-flow: row wrap;

  ${StyledContentItemHomeSecondary} {
    display: flex;
    flex: 1 1 25%;
    flex-flow: column nowrap;

    ${StyledHeadlineEntityLink} {
      flex: 1 1 auto;
    }

    ${StyledCoverEntityLink} {
      order: -1;

      margin-block: 0 12px;
    }

    ${CoverImage} {
      inline-size: auto;
      block-size: auto;
    }

    ${Headline} {
      color: #000000;
      font-weight: 700;
      font-size: 15px;
      font-family: ${fontMerriweather};
      font-style: normal;
      line-height: 140%;
    }

    ${props => props.theme.breakpoints.only("mobile")} {
      flex: 1 1 100%;

      :nth-last-child(2) {
        margin-inline-end: 20px;
      }

      :nth-last-child(-n + 2) {
        flex: 1 1 calc(50% - 10px);

        margin-block-end: 0 !important;
        padding-block-end: 0 !important;

        border: none !important;

        ${StyledCoverEntityLink} {
          display: block;
        }
      }
    }
  }

  ${props => props.theme.breakpoints.only("mobile")} {
    ${StyledContentItemHomeSecondary}:not(:last-child) {
      ${itemBorderStyle}
    }
  }

  ${props => props.theme.breakpoints.up("tablet")} {
    flex-flow: row nowrap;
    gap: 32px;

    margin-block-start: 24px;
    padding-block-start: 24px;
    border-block-start: 1px solid rgba(0, 0, 0, 0.1);
  }

  ${Headline} {
    font-size: 15px;
    line-height: 140%;
  }
`;
